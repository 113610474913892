import React from 'react';
import rotate from '../../../assets/images/rotate_device.gif';

const Rotate: React.FC = () => {
    return (
      <div className="rotate-msg">
          <img src={rotate} alt="Apparaat draaien" />
          <p>Je scherm is een beetje smal! <br />Als je je apparaat op z'n kant legt ziet het er een stuk beter uit!</p>
      </div>
    )
}

export default Rotate