import React, { Fragment, useContext } from 'react';
import Slider from "react-slick";
import { RouteComponentProps } from 'react-router-dom';

import { GameContext } from '../../contexts/GameContext';
import BackButton from '../../parts/BackButton';

import { getCard } from '../../../cards/card_methods';
import { cards } from '../../../cards/data/cards_array';
import { categories } from '../../../cards/data/categories_array';


import CatYellow from '../../../assets/images/categories/cat_yellow.png';
import CatBlue from '../../../assets/images/categories/cat_blue.png';
import CatRed from '../../../assets/images/categories/cat_red.png';
import CatGreen from '../../../assets/images/categories/cat_green.png';

type Props = RouteComponentProps<{player_id?: string}>;

const Chest: React.FC<Props> = (props) => {
    const { game } = useContext(GameContext);

    if(!props.match.params.player_id) return <div></div>;

    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    const playerChest = game.players.find(p => p.id === parseInt(props.match.params.player_id!))?.chest || [];

    let categoryImage: any;

    return (
        <Fragment>
            <BackButton text="" />
            <main>
                <div className="container center chest-detail-wrapper">
                  <h1>Schatkist van {game.players.find(p => p.id === parseInt(props.match.params.player_id!))?.name}</h1>
                  {(playerChest.length <= 0) ? <p>Er zit niks in je schatkist!</p> : <div><p>Veeg over de afbeeldingen om ze te bekijken!</p> <Slider {...sliderSettings}>
                    {playerChest.map(element => {
                      let card = cards.find(card => card.id == element);
                      let color = categories.find(category => category.id === card?.categoryId)?.color || "";

                      switch(color) {
                        case "yellow":
                            categoryImage = CatYellow;
                            break;
                        case "blue":
                            categoryImage = CatBlue;
                            break;
                        case "red":
                            categoryImage = CatRed;
                            break;
                        case "green":
                            categoryImage = CatGreen;
                            break;
                        default:
                            categoryImage = null;
                    }
                      
                      return (
                        <div key={element} className="slider-item">
                          <div className={"slider-card bg-" + color}>
                            <div className="cat-logo-wrapper">
                              <img className="cat-logo" src={categoryImage} alt="Logo van de categorie" />
                            </div>
                            <img src={"/cards/" + getCard(element).imageUrl} alt="Schatkist kaart" />
                          </div>
                        </div>
                      )
                    })}
                    </Slider></div>}
                </div>
            </main>
        </Fragment>
    )
}

export default Chest