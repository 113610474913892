import React, { Fragment } from 'react';
import BackButton from '../../parts/BackButton';

import Flag1 from '../../../assets/images/flag/flag.png';

const Flag: React.FC = () => {
    return (
        <Fragment>
            <BackButton text=""/>
            <main>
                <div className="container center flag">
                    <img className="flag-big" src={Flag1} alt="Vlag"/>
                    <p className="flag-text-right">Kom je er niet uit? <br /><br /> Wie of wat kan je hiermee helpen?</p>
                </div>
            </main>
        </Fragment>
    )
}

export default Flag