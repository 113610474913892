import React from 'react';
import { Link } from 'react-router-dom';

interface OwnProps {
  key: number;
  card: any;
}

type Props = OwnProps;

const StoryCardElement: React.FC<Props> = (props) => {
  return (
    <div className={"storyCardItem bg-" + props.card.color}>
      <Link className="storyCardItemLink" to={"/start/storycard/" + props.card.id}>{props.card.title}</Link>
    </div>
  )
}

export default StoryCardElement