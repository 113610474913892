/**
 * @author Erik Brilleman
 * @version 1.0.0
 * @description Objects for every single category of playingcards. 
 *              id (unique number),
 *              title (string),
 *              description (string),
 *              color (string),
 */
interface ICategory {
  id: number;
  title: string;
  description: string;
  color: string;
}

export const categories:ICategory[] = [
  {
    "id": 0,
    "title": "Groene kaarten",
    "description": "Op deze kaarten staan vragen over jouzelf en de mensen om je heen.",
    "color": "green"
  },
  {
    "id": 1,
    "title": "Blauwe kaarten",
    "description": "Op deze kaarten staan foto's over gevoelens, situaties uit het dagelijkse leven en de samenleving om ons heen. Beschrijf wat je ziet. Vertel daarna waar deze foto je aan doet denken.",
    "color": "blue"
  },
  {
    "id": 2,
    "title": "Rode kaarten",
    "description": "Op deze kaarten staan opdrachten. Ze gaan over de dingen die je doet samen met anderen. En over het leren kennen en het leren aangeven van grenzen.",
    "color": "red"
  },
  {
    "id": 3,
    "title": "Gele kaarten",
    "description": "Op deze kaarten staan zinnen die je moet aanvullen. Je mag bij deze vragen iets over jezelf vertellen.",
    "color": "yellow"
  }
];