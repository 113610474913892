import React, { Fragment, useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Rotate from './components/pages/landing/Rotate';
import Landing from './components/pages/landing/Landing';
import Info from './components/pages/landing/Info';
import Start from './components/pages/home/Start';
import GameScreen from './components/pages/game/GameScreen';
import Card from './components/pages/game/Card';
import StoryCard from './components/pages/storyCards/StoryCard';
import Players from './components/pages/game/Players';
import Flag from './components/pages/game/Flag';
import Star from './components/pages/game/Star';
import Heart from './components/pages/game/Heart';
import Rules from "./components/pages/rules/Rules";
import Strategy from "./components/pages/strategy/Strategy";
import EvenVoorstellen from "./components/pages/strategy/EvenVoorstellen";
import SchatkistGesprek from "./components/pages/strategy/SchatkistGesprek";
import Geluidsfragmenten from "./components/pages/strategy/Geluidsfragmenten";
import StoryCardsList from "./components/pages/storyCards/StoryCardsList";
import EndCard from "./components/pages/game/EndCard";
import EndScreen from "./components/pages/endscreen/EndScreen";
import Chest from "./components/pages/endscreen/Chest";

// context
import GameContextProvider from './components/contexts/GameContext';

const App: React.FC = () => {
    const [dimensionWidth, setDimensionWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          setDimensionWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleResize);
        return () => { 
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleResize);
        }
      }, [dimensionWidth]);

    return (
        <Fragment>
            {(dimensionWidth <= 567) ? (<Rotate />) : ( 
                <div className="content">
                    <BrowserRouter>
                        <GameContextProvider>
                            <Switch>
                                <Route exact path='/' component={Landing}/>
                                <Route exact path='/info' component={Info}/>
                                <Route exact path='/start' component={Start} /> 
                                <Route exact path='/start/rules' component={Rules}/>
                                <Route exact path='/start/strategy' component={Strategy} />
                                <Route exact path='/start/strategy/even-voorstellen' component={EvenVoorstellen}/>
                                <Route exact path='/start/strategy/schatkistgesprek' component={SchatkistGesprek}/>
                                <Route exact path='/start/strategy/geluidsfragmenten' component={Geluidsfragmenten}/>
                                <Route exact path='/start/storycards' component={StoryCardsList} />
                                <Route exact path='/start/storycard/:storycard_id' component={StoryCard}/>
                                <Route exact path='/game' component={GameScreen} />
                                <Route exact path='/game/players' component={Players} />
                                <Route exact path='/game/card/:card_id' component={Card} />
                                <Route exact path='/game/star' component={Star} />
                                <Route exact path='/game/flag' component={Flag} />
                                <Route exact path='/game/heart' component={Heart} />
                                <Route exact path='/game/endcard/:endcard_id' component={EndCard} />
                                <Route exact path='/game/end' component={EndScreen} />
                                <Route exact path='/game/end/chest/:player_id' component={Chest}/>
                            </Switch>
                        </GameContextProvider> 
                    </BrowserRouter>
                </div>
            )}  
        </Fragment>
    );
};

export default App;