import {cards} from './data/cards_array';
import {endCards} from './data/endCards_array';
import {storyCards} from './data/storyCards_array';

let storyCardsArray = storyCards;
let cardsArray = cards;

export const getCard = (id: number) => { 
  return cards.filter(card => card.id == id)[0];
}

export const getEndCard = (id: number) => {
  return endCards.filter(endCard => endCard.id === id)[0];
}

/**
 * Return the number of cards left.
 * @param {boolean} [mustStoryCard=false] Whether it must count the "Speel-kaarten" or the "Verder Speelkaarten". Defaults to false.
 * @returns {number} amount The amount of cards that are left.
 */
export const remainingCards = (mustStoryCard = false) => {
  const amount = (mustStoryCard) ? storyCardsArray.length : cardsArray.length;
  return amount;
}