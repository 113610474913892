/**
 * @author Erik Brilleman
 * @version 1.0.0
 * @description Objects for every single end card, so called "Afsluitingskaarten"
 *              id (unique number),
 *              categoryId (number),
 *              imageUrl (string),
 */
import { ICard } from '../card_interface';

export const endCards:ICard[] = [
  {
    "id":0,
    "categoryId":0,
    "imageUrl":"s_green_40.jpg"
  },
  {
    "id":1,
    "categoryId":1,
    "imageUrl":"s_blue_35.jpg"
  },
  {
    "id":2,
    "categoryId":2,
    "imageUrl":"s_red_40.jpg"
  },
  {
    "id":3,
    "categoryId":3,
    "imageUrl":"s_yellow_40.jpg"
  }
]