import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';

import { GameContext } from '../../contexts/GameContext';
import BackButton from '../../parts/BackButton';

import Chest from '../../../assets/images/chest/chest.png';

const EndScreen: React.FC = () => {
    const { game } = useContext(GameContext);

    return (
        <Fragment>
            <BackButton text="" location="/start"/>
            <main>
                <div className="container center">
                    <h1>Schatkist</h1>
                    <p>Hier kun je nog eens door je kaarten heen kijken</p>
                    <div className="chest-wrapper">
                        {game.players.map(player => {
                            return (
                                <div key={player.id} className="chest-item">
                                    <img src={Chest} alt="Schatkist"/>
                                    <Link to={"/game/end/chest/" + player.id} className="button">{player.name}</Link>
                                </div>
                            )
                            })}
                    </div>
                </div>
            </main>
        </Fragment>
    )
}

export default EndScreen