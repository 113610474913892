import React, { useContext } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { GameContext } from '../../contexts/GameContext';

import Buttons from './Buttons';

import HeartImage from '../../../assets/images/heart/heart.png';

interface OwnProps {

}

type Props = OwnProps & RouteComponentProps;

const Heart: React.FC<Props> = (props) => {
    const { game, nextTurn, done } = useContext(GameContext)

    const nextPlayer = () => {
        nextTurn();

        props.history.goBack();
    }
    
    const lastRound = () => {
        nextTurn();

        done();

        props.history.goBack();
    }

    const endGame = () => {
        props.history.push('/game/end')
    }

    return (
        <main>
            <div className="container center heart">
                    <img className="heart-big" src={HeartImage} alt="Hart"/>
                    {(game.lastRound) ? <p><i className="heart-icon">icon</i>Wil je nog iemand bedanken?</p> : <p><i className="heart-icon">icon</i>Geef jezelf, een ander of de groep <br className="new-line"/>een compliment</p>}

                    {
                        !game.lastRound ? 
                            <Buttons audioFile={(game.lastRound) ? "/audio/heart_end.mp3" : "/audio/heart.mp3"}  next={() => nextPlayer()} done={() => lastRound()} />
                        :
                            game.lastPlayer !== game.turn ?
                                <Buttons audioFile={(game.lastRound) ? "/audio/heart_end.mp3" : "/audio/heart.mp3"}  next={() => nextPlayer()} />
                            :
                                <Buttons audioFile={(game.lastRound) ? "/audio/heart_end.mp3" : "/audio/heart.mp3"} end={() => endGame()} />
                    }
            </div>
        </main>
    )
}

export default Heart