import React, { createRef, useContext, Fragment } from 'react';

import blue from '../../../assets/images/dice/blauw.jpg';
import red from '../../../assets/images/dice/rood.jpg';
import yellow from '../../../assets/images/dice/geel.jpg';
import green from '../../../assets/images/dice/groen.jpg';
import heart from '../../../assets/images/dice/hart.jpg';
import star from '../../../assets/images/dice/ster.jpg';

import { GameContext } from '../../contexts/GameContext';

interface OwnProps {
    displayCard: (cat: number) => void;
    displayEndCard: (cat: number) => void;
    displaySpecial: (cat: string) => void;
}

const Dice: React.FC<OwnProps> = (props) => {
    const { game, isEmptyCategory } = useContext(GameContext);
    const cubeRef = createRef<HTMLDivElement>();

    const clickDice = () => {
        const cube = cubeRef.current;

        if (!cube) return;

        // For each side there are 4 possibilities/rotations that are randomly picked.
        // These hardcoded because of the complexity of rotations.

        const possibility = [
            [[180, 270, 270], [0, 90, 270], [90, 270, 180], [180, 90, 90]],    // green
            [[180, 270, 90], [270, 0, 180], [0, 270, 270], [180, 90, 270]],   // blue
            [[0, 90, 180], [180, 270, 180], [270, 0, 270], [90, 270, 90]],    // red
            [[0, 90, 0], [180, 270, 0], [0, 270, 180], [90, 0, 270]],         // yellow
            [[0, 0, 0], [180, 180, 0], [0, 0, 90], [180, 180, 90]],           // heart
            [[0, 180, 0], [180, 0, 0], [0, 180, 90], [180, 0, 90]]           // star
        ]

        let emptyCategory = true;
        let side = 0;

        if (game.lastRound) {
            side = getRandom(0, 5);
        } else {    
            while (emptyCategory) {
                side = getRandom(0, 5);

                let random = Math.random();
                if(random >= 0.6) {
                    side = getRandom(0, 3);
                }

                if (side === 4 || side === 5 || !isEmptyCategory(side)) emptyCategory = false;
            }
        }


        const rotation = getRandom(0, 3);

        const coor = possibility[side][rotation];
        const roll = getRandom(2, 3);
        const angle = 360;

        const cubeTransform = `rotateX(${coor[0] + (angle * roll)}deg) rotateY(${coor[1] + (angle * roll)}deg) rotateZ(${coor[2] + (angle * roll)}deg)`;

        cube.style.webkitTransform = cubeTransform;
        cube.style.transform = cubeTransform;

        cube.style.pointerEvents = 'none';

        if (side !== 4 && side !== 5) {
            setTimeout(() => (game.lastRound ? props.displayEndCard(side) : props.displayCard(side)), 5000)
        } else {
            if (side === 4) {
                setTimeout(() => props.displaySpecial('heart'), 5000)
            } else {
                setTimeout(() => props.displaySpecial('star'), 5000)
            }
        }
    }

    const getRandom = (min: number, max: number) => {
        return (Math.floor(Math.random() * (max - min + 1) + min));
    }

    return (
        <Fragment>
            {(game.lastRound) ? <div className="end-text">De laatste ronde! <br /> Iedereen dobbelt nog één keer.</div> : null}
            <section className="dice">
                <div id="cube" ref={cubeRef} onClick={clickDice}>
                    <div id="front" className="dice-side">
                        <img src={star} alt="Ster" />
                    </div>

                    <div id="back" className="dice-side">
                        <img src={heart} alt="Hart" />
                    </div>

                    <div id="right" className="dice-side">
                        <img src={red} alt="Rood" />
                    </div>

                    <div id="left" className="dice-side">
                        <img src={yellow} alt="Geel" />
                    </div>

                    <div id="top" className="dice-side">
                        <img src={green} alt="Groen" />
                    </div>

                    <div id="bottom" className="dice-side">
                        <img src={blue} alt="Blauw" />
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Dice