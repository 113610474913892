import React, { createContext, useState, useEffect } from 'react';

import { cards } from '../../cards/data/cards_array';
import { ICard } from '../../cards/card_interface';
import { IStoryCard } from '../../cards/storyCard_interface';
import { storyCards } from '../../cards/data/storyCards_array';
import { categories } from '../../cards/data/categories_array';

export interface IPlayer {
    id: number;
    name: string,
    chest: number[]
}

// currentScreen: 0 = dice, 1 = card, 2 = end
interface IGame {
    currentScreen: number,
    lastRound: boolean,
    lastPlayer: number,
    remaining: number,
    turn: number,
    cardsLeft: ICard[],
    storyCardsLeft: IStoryCard[],
    audioFiles: string[],
    players: IPlayer[]
}

interface IGameContext {
    game: IGame,
    continueGame: Function,
    firstSetup: Function,
    addToChest: Function,
    nextTurn: Function,
    removeCard: Function,
    addPlayer: Function,
    removePlayer: Function,
    changePlayer: Function,
    done: Function,
    resetGame: Function,
    removeAudioFile: Function,
    addAudioFiles: Function,
    isEmptyCategory: (catId: number) => boolean,
    randomCard: Function
}

export const GameContext = createContext({} as IGameContext);

const GameContextProvider: React.FC = (props) => {
    const [game, setGame] = useState<IGame>({
        currentScreen: 0,
        lastRound: false,
        lastPlayer: 0,
        remaining: 0,
        turn: 0,
        cardsLeft: [],
        storyCardsLeft: [],
        audioFiles: [],
        players: [{
            id: 1,
            name: "",
            chest: []
        }]
    });

    // If no localStorage exists, this method will be called from GameScreen.
    const firstSetup: Function = () => {
        setGame({
            ...game, cardsLeft: cards, storyCardsLeft: storyCards
        })

        localStorage.game = JSON.stringify(game);
    }

    const resetGame = () => {
        setGame({
            currentScreen: 0,
            lastRound: false,
            lastPlayer: 0,
            remaining: 0,
            turn: 0,
            cardsLeft: [],
            storyCardsLeft: [],
            audioFiles: [],
            players: [{
                id: 1,
                name: "",
                chest: []
            }]
        });

        localStorage.game = JSON.stringify(game);
    }

    // If localStorage exists, this method will be called from GameScreen.
    const continueGame: Function = () => {
        const history = JSON.parse(localStorage.game);

        try {
            setGame({
                ...history
            })
        } catch {
            localStorage.removeItem('game');
        }
    }

    // Adds a card to the chest of the player. Which player depends on the turn.
    const addToChest = (card_id: number) => {
        const players = game.players.map(player => {
            if (player.id === game.turn + 1) {
                player.chest.push(card_id);

                return player;
            }

            return player;
        })

        setGame({
            ...game, players
        })

        localStorage.game = JSON.stringify(game);
    }

    const nextTurn = () => {
        if (game.turn === game.players.length - 1) {
            setGame({
                ...game, turn: 0
            })
        } else {
            setGame({
                ...game, turn: game.turn + 1
            })
        }

        localStorage.game = JSON.stringify(game);
    }

    const removeCard = (id: number) => {
        const cardsLeft = game.cardsLeft.filter(card => card.id !== id);

        setGame({
            ...game, cardsLeft
        });

        localStorage.game = JSON.stringify(game);
    }

    const addPlayer = () => {
        let id = 1;

        let players = game.players;

        players.forEach(player => {
            id++;
            return player = { id, name: player.name, chest: [] };
        })

        players.push({ id, name: "", chest: [] });

        setGame({
            ...game, players
        })
    }

    const isEmptyCategory = (catId: number) => {
        let cardsInCategory = game.cardsLeft.filter(card => card.categoryId === catId);

        if(cardsInCategory.length > 1) {
            return false;
        } else {
            return true;
        }
    }

    const removePlayer = (id: number) => {
        let players = game.players.filter(player => player.id !== id);

        let newPlayers = [];

        for (let i = 0; i < players.length; i++) {
            let id = i + 1;
            let name = players[i].name;

            newPlayers.push({ id, name, chest: [] })
        }

        setGame({
            ...game, players: newPlayers
        })
    }

    const changePlayer = (id: number, name: string) => {
        const players = game.players.map(player => {
            if (player.id === id) {
                player = { id, name, chest: [] };

                return player
            }

            return player
        })

        setGame({
            ...game, players
        })
    }

    const done = () => {
        const lastPlayer = game.turn;
        const turn = game.turn === game.players.length - 1 ? 0 : game.turn + 1;

        setGame({ ...game, lastRound: true, lastPlayer, turn });

        localStorage.game = JSON.stringify(game);
    }

    const removeAudioFile = (path: string) => {
        const audioFiles = game.audioFiles.filter(audioFile => path !== audioFile);
        setGame({...game, audioFiles});
    }

    const addAudioFiles = async (paths: string[], func: Function) => {       
        const audioFiles = paths;

        setGame({...game, audioFiles });
    }

    const randomCard = (cat: number) => {
        let card: ICard;
        let category;

        //Are there even any cards left? If not, return empty object.
        if (Array.isArray(game.cardsLeft) && game.cardsLeft.length) {
            let cardsMatchingCat = game.cardsLeft.filter(function (value) {
                return value.categoryId === cat;
            });

            //Are there cards matching the given category? If not, return empty object. 
            if (Array.isArray(cardsMatchingCat) && cardsMatchingCat.length) {
                card = cardsMatchingCat[Math.floor(Math.random() * Math.floor(cardsMatchingCat.length))];
                category = categories.find((category: { id: number; }) => category.id === card.categoryId);

                //Remove the card from the array since it's no longer available. 
                game.cardsLeft = game.cardsLeft.filter(function (value) {
                    return value.id !== card.id;
                });

                return { card, category };
            } else {
                return {};
            }
        } else {
            return {};
        }
    }
    
    useEffect(() => {
        localStorage.game && setGame(JSON.parse(localStorage.game));
    }, [])

    return (
        <GameContext.Provider value={{ game, continueGame, firstSetup, addToChest, nextTurn, removeCard, addPlayer, removePlayer, changePlayer, done, resetGame, removeAudioFile, addAudioFiles, isEmptyCategory, randomCard }} >
            {props.children}
        </GameContext.Provider>
    )
}

export default GameContextProvider