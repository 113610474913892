import React, { Fragment } from 'react';
import poster from '../../../assets/images/poster/poster.jpg';
import BackButton from '../../parts/BackButton';

const Rules: React.FC = () => {
    return (
      <Fragment>
        <BackButton text="" location="/start" />
        <main className="speelregels-wrapper">
          <div className="container center">
            <div className="speelregels-video">
              {/* TODO: Change to correct video */}
              <video controls poster={poster}>
                <source src={require('../../../assets/videos/speelregels.mp4')} type="video/mp4" />
              </video>
            </div>
            <div className="speelregels-button">
              <a href={require('../../../assets/files/speelregels.pdf')} target="_BLANK" rel="noopener noreferrer"><div className="button">Speelregels lezen</div></a>
            </div>
          </div>
        </main>
      </Fragment>
    )
}

export default Rules