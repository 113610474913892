import React, { useContext } from 'react'

import { RouteComponentProps } from 'react-router-dom';
import { GameContext } from '../../contexts/GameContext';

import Buttons from './Buttons';

import Star1 from '../../../assets/images/star/star.png';

interface OwnProps {

}

type Props = OwnProps & RouteComponentProps;

const Star: React.FC<Props> = (props) => {
    const { game, nextTurn, done } = useContext(GameContext)

    const nextPlayer = () => {
        nextTurn();

        props.history.goBack();
    }

    const lastRound = () => {
        nextTurn();

        done();

        props.history.goBack();
    }

    const endGame = () => {
        props.history.push('/game/end')
    }

    return (
        <main>
            <div className="container center star">
                {(game.lastRound) ? null : <p><i className="star-icon">icon</i>Wat je niet van mij weet...</p>}
                <img className="star-big" src={Star1} alt="Ster_Groot"/>
                {(game.lastRound) ? null : <p className="star-text-left"><i className="star-icon">icon</i>Wat ik jou zou willen vragen...</p>}
                {(game.lastRound) ? <p className="start-text-bottom" ><i className="star-icon">icon</i>Wat ik nog niet wist...</p> : null}

                {
                    !game.lastRound ? 
                        <Buttons audioFile={(game.lastRound) ? "/audio/star_end.mp3" : "/audio/star.mp3"} next={() => nextPlayer()} done={() => lastRound()} />
                    :
                        game.lastPlayer !== game.turn ?
                            <Buttons audioFile={(game.lastRound) ? "/audio/star_end.mp3" : "/audio/star.mp3"} next={() => nextPlayer()} />
                        :
                            <Buttons audioFile={(game.lastRound) ? "/audio/star_end.mp3" : "/audio/star.mp3"} end={() => endGame()} />
                }
            </div>
        </main>
    )
}

export default Star