/**
 * @author Erik Brilleman
 * @version 1.0.0
 * @description Objects for every single story card, so called "Doorkletskaarten".
 *              id (unique number),
 *              title (string),
 *              color (string),
 */
import { IStoryCard } from '../storyCard_interface';

export const storyCards:IStoryCard[] = [ 
  {
    "id": 1,
    "title": "Samen delen",
    "color": "blue"
  },
  {
    "id": 2,
    "title": "Jouw verhaal",
    "color": "blue"
  },
  {
    "id": 3,
    "title": "Waarderen",
    "color": "blue"
  },
  {
    "id": 4,
    "title": "Dierbare dingen",
    "color": "blue"
  },
  {
    "id": 5,
    "title": "Muziek die raakt",
    "color": "red"
  },
  {
    "id": 6,
    "title": "Samen koken",
    "color": "red"
  },
  {
    "id": 7,
    "title": "Samen op pad",
    "color": "red"
  },
  {
    "id": 8,
    "title": "Geluk In Kleine Dingen",
    "color": "red"
  },
  {
    "id": 9,
    "title": "Dipje",
    "color": "green"
  },
  {
    "id": 10,
    "title": "Filosoferen",
    "color": "green"
  },
  {
    "id": 11,
    "title": "Mooi toch?",
    "color": "green"
  },
  {
    "id": 12,
    "title": "Een beetje magie",
    "color": "green"
  }
]