import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface OwnProps {
  read?: () => void;
  next?: () => void;
  skip?: () => void;
  end?: () => void;
  done?: () => void;
  audioFile?: string;
  blue?: boolean;
  nextCard?: boolean;
  playerName?: string;
  storyCard?: boolean;
}

const Buttons: React.FC<OwnProps> = (props) => {
    const history = useHistory();
    let audioFragment = new Audio((props.audioFile) ? props.audioFile : "");

    useEffect(() => {
      return () => {
        audioFragment.pause();
      }
    })

    const toggle = () => {
      if(audioFragment.paused) {
        audioFragment.play();
      } else {
        audioFragment.pause();
      }
    }

    return (
      <aside className="interactionButtons">
        <ul>
          {(!props.playerName) ? null : <li className="player">Speler: {props.playerName}</li>}
          {(!props.audioFile) ? null : <li><button onClick={() => toggle()}><i className="ear">icon</i>{(props.blue) ? "Geluidsfragment" : "Voorlezen"}</button></li>}
          {(!props.next) ? null : <li><button onClick={props.next}><i className="next">icon</i>Volgende <br className="new-line" />{(props.nextCard) ? "Kaart" : "Speler"}</button></li>}
          {(!props.skip) ? null : <li><button onClick={props.skip}><i className="skip">icon</i>Overslaan</button></li>}
          {(!props.end) ? null : <li><button onClick={props.end}><i className="chest">icon</i>Einde</button></li>}
          <div className="bottom">
            {(props.storyCard) ? null : <li><button onClick={() => history.push('/game/flag')}><i className="flag">icon</i>Hulplijn</button></li>}
            {(!props.done) ? null : <li><button onClick={props.done}><i className="done">icon</i>Afsluiting</button></li>}        
          </div>
        </ul>
      </aside>
    )
}

export default Buttons