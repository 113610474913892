import React, { Fragment, useEffect } from 'react';
import BackButton from '../../parts/BackButton';

const Geluidsfragmenten: React.FC = (props:any) => {
  const audioFiles = [
    new Audio(require('../../../assets/audio/blablabla.mp3')),
    new Audio(require('../../../assets/audio/blaffende_hond.mp3')),
    new Audio(require('../../../assets/audio/calm_wintergarden.mp3')),
    new Audio(require('../../../assets/audio/doei_doei.mp3')),
    new Audio(require('../../../assets/audio/gelach.mp3')),
    new Audio(require('../../../assets/audio/haardvuur.mp3')),
    new Audio(require('../../../assets/audio/kerkklokken.mp3')),
    new Audio(require('../../../assets/audio/bevalling_huilende_baby.mp3')),
    new Audio(require('../../../assets/audio/krekel.mp3')),
    new Audio(require('../../../assets/audio/kussen_is_gezond.mp3')),
    new Audio(require('../../../assets/audio/miauwende_kat.mp3')),
    new Audio(require('../../../assets/audio/ontbijtgeluiden.mp3')),
    new Audio(require('../../../assets/audio/radio_oranje.mp3')),
    new Audio(require('../../../assets/audio/receptie.mp3')),
    new Audio(require('../../../assets/audio/regen.mp3')),
    new Audio(require('../../../assets/audio/schoolplein.mp3')),
    new Audio(require('../../../assets/audio/snurken.mp3')),
    new Audio(require('../../../assets/audio/wedding_march.mp3')),
  ]

  useEffect(() => {
    return () => {
      for(let i = 0; i < audioFiles.length; i++) {
        audioFiles[i].pause();
      }
    }
  }, [audioFiles])

  const toggle = (number:number, e:React.MouseEvent<HTMLDivElement>) => {   
    if(audioFiles[number].paused) {
      audioFiles[number].play();
      e.currentTarget.classList.add("playing");
    } else {
      audioFiles[number].pause();
      e.currentTarget.classList.remove("playing");
    }

    for(let i = 0; i < audioFiles.length; i++) {
      if(i !== number) {
        audioFiles[i].pause();
      }
    }

    const audioItems = document.getElementsByClassName("audioItem");
    for(let i = 0; i < audioItems.length; i++) {
      if(i !== number) {
        audioItems[i].classList.remove("playing");
      }
    }
  }

  return (
    <Fragment>
      <BackButton text="" location="/start/strategy"/>
      <main className="geluidsfragmenten-wrapper">
        <div className="container center">
          <h1>Geluidsfragmenten</h1>
          <div className="strategy-block justify">
            <p>Hier staan alle geluidsfragmenten op een rij, om naast het spelbord te gebruiken. Klik op een cijfer om geluidsfragment te luisteren of stop te zetten.</p>
          </div>
          <div className="geluidsfragmenten-grid">
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(0, e)}>1</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(1, e)}>2</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(2, e)}>3</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(3, e)}>4</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(4, e)}>5</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(5, e)}>6</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(6, e)}>7</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(7, e)}>8</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(8, e)}>9</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(9, e)}>10</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(10, e)}>11</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(11, e)}>12</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(12, e)}>13</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(13, e)}>14</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(14, e)}>15</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(15, e)}>16</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(16, e)}>17</div></div>
            <div className="grid-item"><div className="audioItem" onClick={(e) => toggle(17, e)}>18</div></div>
          </div>
        </div>
      </main>
      <div className="geluidsfragmenten-image"></div>
    </Fragment>
  )
}


export default Geluidsfragmenten