import React, { Fragment } from 'react';
import BackButton from '../../parts/BackButton';
import Button from '../../parts/Button';
import werkvormen from '../../../assets/images/titles/werkvormen.png';

const Strategy: React.FC = () => {
  return (
    <Fragment>
      <BackButton text="" location="/start"/>
      <main>
        <div className="container center werkvormen">
          <img className="werkvormen-title" src={werkvormen} alt="Titel Werkvormen"/>
          <div className="button-box">
            <Button uri="/start/strategy/even-voorstellen" text="Even voorstellen"/>
            <Button uri="/start/strategy/schatkistgesprek" text="Schatkistgesprek"/>
            <Button uri="/start/strategy/geluidsfragmenten" text="Geluidsfragmenten"/>
          </div>
        </div>
      </main>
    </Fragment>
  )
}


export default Strategy