import React, { useState, useEffect, useContext } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { GameContext } from '../../contexts/GameContext';

import { getEndCard } from '../../../cards/card_methods';
import { categories } from '../../../cards/data/categories_array';

import Camera from './Camera';
import Buttons from './Buttons';

import CatYellow from '../../../assets/images/categories/cat_yellow.png';
import CatBlue from '../../../assets/images/categories/cat_blue.png';
import CatRed from '../../../assets/images/categories/cat_red.png';
import CatGreen from '../../../assets/images/categories/cat_green.png';

interface OwnProps {

}

type Props = OwnProps & RouteComponentProps<{endcard_id?: string}>;

const EndCard: React.FC<Props> = (props) => {
    const { game, nextTurn, done } = useContext(GameContext);
    const [color, setColor] = useState("");
    const [imageURL, setImageURL] = useState("");
    const [audioURL, setAudioURL] = useState("");

    let card: any;
    let audioPath: string;
    let categoryImage: any;

    useEffect(() => {
        if(props.match.params.endcard_id) {            
            card = getEndCard(+props.match.params.endcard_id);
            let catColor = categories.find(category => category.id === card.categoryId)?.color || "";

            setImageURL('/cards/' + card.imageUrl);
            setColor(catColor);

            audioPath = '/cards/' + card.imageUrl.replace(".jpg", ".mp3");
            setAudioURL(audioPath);
        }
    }, []);

    if(!props.match.params.endcard_id) return <div></div>;

    const nextPlayer = () => {
        nextTurn();

        props.history.goBack();
    }

    const lastRound = () => {
        nextTurn();

        done();

        props.history.goBack();
    }

    const endGame = () => {
        props.history.push('/game/end')
    }

    switch(color) {
        case "yellow":
            categoryImage = CatYellow;
            break;
        case "blue":
            categoryImage = CatBlue;
            break;
        case "red":
            categoryImage = CatRed;
            break;
        case "green":
            categoryImage = CatGreen;
            break;
        default:
            categoryImage = null;
    }

    return (
        <main>
            <div className="container center">
                <div className={"card bg-" + color}>
                    <img className="cat-logo" src={categoryImage} alt="Logo van de categorie"/>
                    <div className="card-image">
                        {(game.lastRound && color === 'blue') ? <Camera /> : <img src={imageURL} alt="Kaart"/>}
                    </div>
                </div>
            </div>

            {
            !game.lastRound ?
                <Buttons audioFile={"/audio" + audioURL} next={() => nextPlayer()} done={() => lastRound()} playerName={game.players[game.turn].name} />
            : game.lastPlayer !== game.turn ?
                <Buttons audioFile={"/audio" + audioURL} next={() => nextPlayer()} playerName={game.players[game.turn].name} />
            :
                <Buttons audioFile={"/audio" + audioURL} end={() => endGame()} playerName={game.players[game.turn].name} />
            }
        </main>
    )
}

export default EndCard