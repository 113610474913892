import React from 'react';
import { useHistory } from 'react-router-dom';

interface OwnProps {
  text: string;
  location?: string;
}

type Props = OwnProps;

const BackButton: React.FC<Props> = (props) => {
    const history = useHistory();
    return (
      <div className="menu-button" onClick={() => (props.location) ? history.push(props.location) : history.goBack()}><span className="menu-button-text">{props.text}</span><i className="arrow">back</i></div>
    );
};

export default BackButton