import React, { Fragment } from 'react';
import BackButton from '../../parts/BackButton';
import plaatje4 from '../../../assets/images/werkvormen/werkvormen_4.png';

const SchatkistGesprek: React.FC = () => {
  return (
    <Fragment>
      <BackButton text="" location="/start/strategy"/>
      <main>
        <div className="container center">
          <h1>Schatkistgesprek</h1>
          <p>Blader na het spelen nog eens door de kaarten in je schatkist.</p>
          <div className="werkvormen-image-box">
            <img src={plaatje4} alt="Plaatje 4"/>
          </div>
          <div className="strategy-block">
            <p>Wat vond je mooie momenten? <br /> 
            En wat vond je misschien moeilijk? <br />
            Zijn er kaarten die je vandaag hebt overgeslagen, maar die je wel op een ander moment zou willen bespreken?</p>
          </div>
        </div>
      </main>
    </Fragment>
  )
}


export default SchatkistGesprek