import React, { useState, Fragment } from 'react';
import Webcam from 'react-webcam';

const Camera: React.FC = () => {
  const [canUseCamera, setCameraUsage] = useState(false);
  const [error, setError] = useState("");
  const [devices, setDevices] = useState([]);

  const handleDevices = React.useCallback(
    mediaDevices =>
      setDevices(mediaDevices.filter(({kind} : {kind : any}) => kind === "videoinput")),
    [setDevices]
  );

  React.useEffect(
    () => {
      (async () => {   
        try {
          await navigator.mediaDevices.getUserMedia({audio: false, video: true});   
          await navigator.mediaDevices.enumerateDevices().then(handleDevices);   
          setCameraUsage(true);
        } catch (err) {
          setCameraUsage(false);
        }
      })();
    },
    [handleDevices]
  ); 

  const webcamRef:any = React.useRef(null);
  const capture = React.useCallback(() => {
    const downloadImageFile = (dataUri:string) => {
      if(!dataUri || dataUri === null) {
        setError("Waarschijnlijk moet je even toestemming geven dat we je camera mogen gebruiken!");
      } else {
        let blob = dataURItoBlob(dataUri);
        downloadImageFileFomBlob(blob);
      }
    }

    downloadImageFile(webcamRef.current.getScreenshot());
  }, [webcamRef]);

  const dataURItoBlob = (dataURI:string) => {
    let byteString = atob(dataURI.split(',')[1]);
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], {type: mimeString});
  }

  const downloadImageFileFomBlob = (blob:object) => {    
    window.URL = window.webkitURL || window.URL;

    let anchor = document.createElement('a');
    anchor.download = `kletsborden_${Date.now()}.jpg`;
    anchor.href = window.URL.createObjectURL(blob);
    anchor.dispatchEvent(new MouseEvent('click'));
  }

  return (
    <div className="camera-wrapper">
      {(error !== "") ? <p className="camera-error">{error}</p> : null}
      {(canUseCamera && devices.length > 0) ? (
        <Fragment>
          <Webcam className="camera" audio={false} ref={webcamRef} screenshotFormat="image/jpeg" screenshotQuality={1} videoConstraints={{facingMode: "user"}} />
          <button className="button" onClick={capture}>Maak foto!</button>
        </Fragment>
      ) : (
        <img src="/cards/s_blue_35.jpg" alt="Kaart"/>
      )}

    </div>
  )
}

export default Camera;