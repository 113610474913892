import React, { useEffect, Fragment } from 'react';

import BackButton from '../../parts/BackButton';

const Start: React.FC = (props: any) => {
    useEffect(() => {
        // localStorage.game && props.history.push("/game");
    }, []);

    const openRules = () => {
        props.history.push('/start/rules');
    }

    const openPlayers = () => {
        props.history.push('/game/players');
    }

    const openStoryCards = () => {
        props.history.push('/start/storycards');
    }

    const openStrategy = () => {
        props.history.push('/start/strategy');
    }

    return (
        <Fragment>
            <BackButton text="" location="/"/>
            <div className="startWrapper">
                <div className="startItems">
                    <div className="startItem speelregels" onClick={() => openRules()}></div>
                    <div className="startItem spelen" onClick={() => openPlayers()}></div>
                    <div className="startItem doorkletsen" onClick={() => openStoryCards()}></div>
                    <div className="startItem werkvormen" onClick={() => openStrategy()}></div>
                </div>
            </div>
        </Fragment>
    )
}

export default Start;