import React, { useContext, useEffect } from 'react'
import Dice from './Dice'
import { categories } from '../../../cards/data/categories_array';
import { ICard } from '../../../cards/card_interface';

//Context
import { GameContext } from '../../contexts/GameContext';

// Methods
import { getEndCard } from '../../../cards/card_methods';

const GameScreen: React.FC = (props: any) => {
    const { game, removeCard, randomCard } = useContext(GameContext);

    const displayCard = (cat: number) => {
        const { card } = randomCard(cat);

        if (!card) return;

        removeCard(card.id);

        game.currentScreen = 1;

        props.history.push(`game/card/${card.id}`);
    }

    const displayEndCard = (cat: number) => {
        const endCard = getEndCard(cat);

        if (!endCard) return;

        game.currentScreen = 1;

        props.history.push(`game/endcard/${endCard.id}`);
    }

    const displaySpecial = (cat: string) => {
        if (cat === 'star') {
            props.history.push('game/heart');
        } else {
            props.history.push('game/star');
        }
    }

    useEffect(() => {
        !localStorage.game && props.history.push("/game/players");
    }, [props.history]);

    return (
        <main>
            <div className="container center">
                <Dice displayCard={displayCard} displayEndCard={displayEndCard} displaySpecial={displaySpecial} />
                <p className="player-name">{game.players[game.turn].name}</p>
            </div>
        </main>
    )
}

export default GameScreen