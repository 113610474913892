import React, { useContext, useEffect, Fragment } from 'react';

import { GameContext } from '../../contexts/GameContext';
import BackButton from '../../parts/BackButton';
import spelers from '../../../assets/images/titles/spelers.png';

const Players = (props: any) => {
    const { game, firstSetup, addPlayer, removePlayer, changePlayer, resetGame } = useContext(GameContext);

    useEffect(() => {
        localStorage.removeItem("game")
        resetGame();
    }, [])

    const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const inputElements = e.currentTarget.getElementsByTagName('input');
        let valid = true;

        if(inputElements.length >= 2) {
            for(let i =0; i < inputElements.length; i++) {
                if(inputElements[i].value === "" || inputElements[i].value == null) {
                    valid = false;
                }
            }

            if(valid) {
                firstSetup();
                props.history.push("/game");
            }
        }
    }

    const displayInput = game.players.map(player => {
        return (
            <div key={player.id}>
                <input className="button" name="" placeholder="Naam..." type="text" onChange={ (e) => changePlayerName(e, player.id) } value={player.name} autoFocus/>
                <div className="button remove" onClick={ (e) => removeButton(e, player.id) } id={ player.id.toString() }>X</div>
            </div>
        )
    });

    const changePlayerName = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        let name = e.target.value;
        
        changePlayer(id, name);
    }

    const removeButton = (e:any, id: number) => {
        if(e.currentTarget.parentNode.parentNode.getElementsByTagName('input').length > 1) {
            removePlayer(id);
        } 
    }

    return (
        <Fragment>
            <BackButton text="" location="/start" />
            <div className="players-image flowerpower"></div>
            <div className="players-image rabbit"></div>
            <main>
                <div className="container center players">
                    <img className="players-title" src={spelers} alt="Titel spelers"/>
                    
                        <form onSubmit={(e) => onSubmitHandler(e)}>
                            <div className="new-players">
                                { displayInput }
                            </div>
                            <div className="form-flex">
                                <input type="button" className="button" value="Nieuwe speler" onClick={ () => addPlayer() } />
                                <input type="submit" className="button" value="Spelen"></input>
                            </div>
                        </form>
                </div>
            </main>
            <div className="players-image haar"></div>
            <div className="players-image indian"></div>
            <div className="players-image fire"></div>
        </Fragment>
    )
}

export default Players;