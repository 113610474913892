import React, { Fragment } from 'react';
import BackButton from '../../parts/BackButton';

const EvenVoorstellen: React.FC = () => {
  return (
    <Fragment>
      <BackButton text="" location="/start/strategy"/>
      <main>
        <div className="container center voorstellen">
          <h1>Even voorstellen</h1>
          <p>
            De speelkaarten kun je los gebruiken om een ander soort voorstel-rondje te organiseren, waarin je nog eens echt iets van
            iemand te weten komt.
          </p>
          
          <p>
            <em>Bijvoorbeeld:</em><br />
            &bull; Gebruik fotokaarten en kletskaarten om jezelf voor te stellen. <br />
            &bull; Kies een aantal kaarten en vraag mensen in tweetallen in gesprek te gaan, om iets te weten over de ander wat je nog niet wist.
          </p>
        </div>
      </main>
      <div className="upside-down"></div>
      <div className="lamp"></div>
    </Fragment>
  )
}


export default EvenVoorstellen