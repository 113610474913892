import React, { Fragment } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import Buttons from '../game/Buttons';
import BackButton from '../../parts/BackButton';

import pageTitle from '../../../assets/images/titles/doorkletsen.png';

type Props = RouteComponentProps<{storycard_id?: string}>;

const StoryCard: React.FC<Props> = (props) => {
    const history = useHistory();
    if(!props.match.params.storycard_id) return <div></div>;

    const id = parseInt(props.match.params.storycard_id);

    const nextCard = () => {
      history.push('/start/storycard/' + ((id < 12) ? (id + 1) : 1));
    }

    return (
        <Fragment>
          <BackButton text="" location="/start/storycards"/>
          <main>
              <div className="container center">
                  <img className="storyCard-title" src={pageTitle} alt="Titel"/>
                  <div className="storyCard">
                    <img src={"/storycards/sc_" + id + ".png"} alt="Doorkletskaart"/>
                  </div>
              </div>

              <Buttons audioFile={"/audio/storycards/sc_" + props.match.params.storycard_id + ".mp3"} next={() => nextCard()} nextCard storyCard/>
          </main>
        </Fragment>
    )
}

export default StoryCard