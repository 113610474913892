/**
 * @author Erik Brilleman
 * @version 1.0.0
 * @description Objects for every single card, so called "Speel Kaarten"
 *              id (unique number),
 *              categoryId (number),
 *              imageUrl (string),
 */
import { ICard } from '../card_interface';

export const cards:ICard[] = [ 
  { 
    "id":0,
    "categoryId":0,
    "imageUrl":"s_green_0.jpg"
  },
  { 
    "id":1,
    "categoryId":0,
    "imageUrl":"s_green_1.jpg"
  },
  { 
    "id":2,
    "categoryId":0,
    "imageUrl":"s_green_2.jpg"
  },
  { 
    "id":3,
    "categoryId":0,
    "imageUrl":"s_green_3.jpg"
  },
  { 
    "id":4,
    "categoryId":0,
    "imageUrl":"s_green_4.jpg"
  },
  { 
    "id":5,
    "categoryId":0,
    "imageUrl":"s_green_5.jpg"
  },
  { 
    "id":6,
    "categoryId":0,
    "imageUrl":"s_green_6.jpg"
  },
  { 
    "id":7,
    "categoryId":0,
    "imageUrl":"s_green_7.jpg"
  },
  { 
    "id":8,
    "categoryId":0,
    "imageUrl":"s_green_8.jpg"
  },
  { 
    "id":9,
    "categoryId":0,
    "imageUrl":"s_green_9.jpg"
  },
  { 
    "id":10,
    "categoryId":0,
    "imageUrl":"s_green_10.jpg"
  },
  { 
    "id":11,
    "categoryId":0,
    "imageUrl":"s_green_11.jpg"
  },
  { 
    "id":12,
    "categoryId":0,
    "imageUrl":"s_green_12.jpg"
  },
  { 
    "id":13,
    "categoryId":0,
    "imageUrl":"s_green_13.jpg"
  },
  { 
    "id":14,
    "categoryId":0,
    "imageUrl":"s_green_14.jpg"
  },
  { 
    "id":15,
    "categoryId":0,
    "imageUrl":"s_green_15.jpg"
  },
  { 
    "id":16,
    "categoryId":0,
    "imageUrl":"s_green_16.jpg"
  },
  { 
    "id":17,
    "categoryId":0,
    "imageUrl":"s_green_17.jpg"
  },
  { 
    "id":18,
    "categoryId":0,
    "imageUrl":"s_green_18.jpg"
  },
  { 
    "id":19,
    "categoryId":0,
    "imageUrl":"s_green_19.jpg"
  },
  { 
    "id":20,
    "categoryId":0,
    "imageUrl":"s_green_20.jpg"
  },
  { 
    "id":21,
    "categoryId":0,
    "imageUrl":"s_green_21.jpg"
  },
  { 
    "id":22,
    "categoryId":0,
    "imageUrl":"s_green_22.jpg"
  },
  { 
    "id":23,
    "categoryId":0,
    "imageUrl":"s_green_23.jpg"
  },
  { 
    "id":24,
    "categoryId":0,
    "imageUrl":"s_green_24.jpg"
  },
  { 
    "id":25,
    "categoryId":0,
    "imageUrl":"s_green_25.jpg"
  },
  { 
    "id":26,
    "categoryId":0,
    "imageUrl":"s_green_26.jpg"
  },
  { 
    "id":27,
    "categoryId":0,
    "imageUrl":"s_green_27.jpg"
  },
  { 
    "id":28,
    "categoryId":0,
    "imageUrl":"s_green_28.jpg"
  },
  { 
    "id":29,
    "categoryId":0,
    "imageUrl":"s_green_29.jpg"
  },
  { 
    "id":30,
    "categoryId":0,
    "imageUrl":"s_green_30.jpg"
  },
  { 
    "id":31,
    "categoryId":0,
    "imageUrl":"s_green_31.jpg"
  },
  { 
    "id":32,
    "categoryId":0,
    "imageUrl":"s_green_32.jpg"
  },
  { 
    "id":33,
    "categoryId":0,
    "imageUrl":"s_green_33.jpg"
  },
  { 
    "id":34,
    "categoryId":0,
    "imageUrl":"s_green_34.jpg"
  },
  { 
    "id":35,
    "categoryId":0,
    "imageUrl":"s_green_35.jpg"
  },
  { 
    "id":36,
    "categoryId":0,
    "imageUrl":"s_green_36.jpg"
  },
  { 
    "id":37,
    "categoryId":0,
    "imageUrl":"s_green_37.jpg"
  },
  { 
    "id":38,
    "categoryId":0,
    "imageUrl":"s_green_38.jpg"
  },
  { 
    "id":39,
    "categoryId":0,
    "imageUrl":"s_green_39.jpg"
  },
  { 
    "id":40,
    "categoryId":1,
    "imageUrl":"s_blue_0.jpg"
  },
  { 
    "id":41,
    "categoryId":1,
    "imageUrl":"s_blue_1.jpg"
  },
  { 
    "id":42,
    "categoryId":1,
    "imageUrl":"s_blue_2.jpg"
  },
  { 
    "id":43,
    "categoryId":1,
    "imageUrl":"s_blue_3.jpg"
  },
  { 
    "id":44,
    "categoryId":1,
    "imageUrl":"s_blue_4.jpg"
  },
  { 
    "id":45,
    "categoryId":1,
    "imageUrl":"s_blue_5.jpg"
  },
  { 
    "id":46,
    "categoryId":1,
    "imageUrl":"s_blue_6.jpg"
  },
  { 
    "id":47,
    "categoryId":1,
    "imageUrl":"s_blue_7.jpg"
  },
  { 
    "id":48,
    "categoryId":1,
    "imageUrl":"s_blue_8.jpg"
  },
  { 
    "id":49,
    "categoryId":1,
    "imageUrl":"s_blue_9.jpg"
  },
  { 
    "id":50,
    "categoryId":1,
    "imageUrl":"s_blue_10.jpg"
  },
  { 
    "id":51,
    "categoryId":1,
    "imageUrl":"s_blue_11.jpg"
  },
  { 
    "id":52,
    "categoryId":1,
    "imageUrl":"s_blue_12.jpg"
  },
  { 
    "id":53,
    "categoryId":1,
    "imageUrl":"s_blue_13.jpg"
  },
  { 
    "id":54,
    "categoryId":1,
    "imageUrl":"s_blue_14.jpg"
  },
  { 
    "id":55,
    "categoryId":1,
    "imageUrl":"s_blue_15.jpg"
  },
  { 
    "id":56,
    "categoryId":1,
    "imageUrl":"s_blue_16.jpg"
  },
  { 
    "id":57,
    "categoryId":1,
    "imageUrl":"s_blue_17.jpg"
  },
  { 
    "id":58,
    "categoryId":1,
    "imageUrl":"s_blue_18.jpg"
  },
  { 
    "id":59,
    "categoryId":1,
    "imageUrl":"s_blue_19.jpg"
  },
  { 
    "id":60,
    "categoryId":1,
    "imageUrl":"s_blue_20.jpg"
  },
  { 
    "id":61,
    "categoryId":1,
    "imageUrl":"s_blue_21.jpg"
  },
  { 
    "id":62,
    "categoryId":1,
    "imageUrl":"s_blue_22.jpg"
  },
  { 
    "id":63,
    "categoryId":1,
    "imageUrl":"s_blue_23.jpg"
  },
  { 
    "id":64,
    "categoryId":1,
    "imageUrl":"s_blue_24.jpg"
  },
  { 
    "id":65,
    "categoryId":1,
    "imageUrl":"s_blue_25.jpg"
  },
  { 
    "id":66,
    "categoryId":1,
    "imageUrl":"s_blue_26.jpg"
  },
  { 
    "id":67,
    "categoryId":1,
    "imageUrl":"s_blue_27.jpg"
  },
  { 
    "id":68,
    "categoryId":1,
    "imageUrl":"s_blue_28.jpg"
  },
  { 
    "id":69,
    "categoryId":1,
    "imageUrl":"s_blue_29.jpg"
  },
  { 
    "id":70,
    "categoryId":1,
    "imageUrl":"s_blue_30.jpg"
  },
  { 
    "id":71,
    "categoryId":1,
    "imageUrl":"s_blue_31.jpg"
  },
  { 
    "id":72,
    "categoryId":1,
    "imageUrl":"s_blue_32.jpg"
  },
  { 
    "id":73,
    "categoryId":1,
    "imageUrl":"s_blue_33.jpg"
  },
  { 
    "id":74,
    "categoryId":1,
    "imageUrl":"s_blue_34.jpg"
  },
  { 
    "id":75,
    "categoryId":2,
    "imageUrl":"s_red_0.jpg"
  },
  { 
    "id":76,
    "categoryId":2,
    "imageUrl":"s_red_1.jpg"
  },
  { 
    "id":77,
    "categoryId":2,
    "imageUrl":"s_red_2.jpg"
  },
  { 
    "id":78,
    "categoryId":2,
    "imageUrl":"s_red_3.jpg"
  },
  { 
    "id":79,
    "categoryId":2,
    "imageUrl":"s_red_4.jpg"
  },
  { 
    "id":80,
    "categoryId":2,
    "imageUrl":"s_red_5.jpg"
  },
  { 
    "id":81,
    "categoryId":2,
    "imageUrl":"s_red_6.jpg"
  },
  { 
    "id":82,
    "categoryId":2,
    "imageUrl":"s_red_7.jpg"
  },
  { 
    "id":83,
    "categoryId":2,
    "imageUrl":"s_red_8.jpg"
  },
  { 
    "id":84,
    "categoryId":2,
    "imageUrl":"s_red_9.jpg"
  },
  { 
    "id":85,
    "categoryId":2,
    "imageUrl":"s_red_10.jpg"
  },
  { 
    "id":86,
    "categoryId":2,
    "imageUrl":"s_red_11.jpg"
  },
  { 
    "id":87,
    "categoryId":2,
    "imageUrl":"s_red_12.jpg"
  },
  { 
    "id":88,
    "categoryId":2,
    "imageUrl":"s_red_13.jpg"
  },
  { 
    "id":89,
    "categoryId":2,
    "imageUrl":"s_red_14.jpg"
  },
  { 
    "id":90,
    "categoryId":2,
    "imageUrl":"s_red_15.jpg"
  },
  { 
    "id":91,
    "categoryId":2,
    "imageUrl":"s_red_16.jpg"
  },
  { 
    "id":92,
    "categoryId":2,
    "imageUrl":"s_red_17.jpg"
  },
  { 
    "id":93,
    "categoryId":2,
    "imageUrl":"s_red_18.jpg"
  },
  { 
    "id":94,
    "categoryId":2,
    "imageUrl":"s_red_19.jpg"
  },
  { 
    "id":95,
    "categoryId":2,
    "imageUrl":"s_red_20.jpg"
  },
  { 
    "id":96,
    "categoryId":2,
    "imageUrl":"s_red_21.jpg"
  },
  { 
    "id":97,
    "categoryId":2,
    "imageUrl":"s_red_22.jpg"
  },
  { 
    "id":98,
    "categoryId":2,
    "imageUrl":"s_red_23.jpg"
  },
  { 
    "id":99,
    "categoryId":2,
    "imageUrl":"s_red_24.jpg"
  },
  { 
    "id":100,
    "categoryId":2,
    "imageUrl":"s_red_25.jpg"
  },
  { 
    "id":101,
    "categoryId":2,
    "imageUrl":"s_red_26.jpg"
  },
  { 
    "id":102,
    "categoryId":2,
    "imageUrl":"s_red_27.jpg"
  },
  { 
    "id":103,
    "categoryId":2,
    "imageUrl":"s_red_28.jpg"
  },
  { 
    "id":104,
    "categoryId":2,
    "imageUrl":"s_red_29.jpg"
  },
  { 
    "id":105,
    "categoryId":2,
    "imageUrl":"s_red_30.jpg"
  },
  { 
    "id":106,
    "categoryId":2,
    "imageUrl":"s_red_31.jpg"
  },
  { 
    "id":107,
    "categoryId":2,
    "imageUrl":"s_red_32.jpg"
  },
  { 
    "id":108,
    "categoryId":2,
    "imageUrl":"s_red_33.jpg"
  },
  { 
    "id":109,
    "categoryId":2,
    "imageUrl":"s_red_34.jpg"
  },
  { 
    "id":110,
    "categoryId":2,
    "imageUrl":"s_red_35.jpg"
  },
  { 
    "id":111,
    "categoryId":2,
    "imageUrl":"s_red_36.jpg"
  },
  { 
    "id":112,
    "categoryId":2,
    "imageUrl":"s_red_37.jpg"
  },
  { 
    "id":113,
    "categoryId":2,
    "imageUrl":"s_red_38.jpg"
  },
  { 
    "id":114,
    "categoryId":2,
    "imageUrl":"s_red_39.jpg"
  },
  { 
    "id":115,
    "categoryId":3,
    "imageUrl":"s_yellow_0.jpg"
  },
  { 
    "id":116,
    "categoryId":3,
    "imageUrl":"s_yellow_1.jpg"
  },
  { 
    "id":117,
    "categoryId":3,
    "imageUrl":"s_yellow_2.jpg"
  },
  { 
    "id":118,
    "categoryId":3,
    "imageUrl":"s_yellow_3.jpg"
  },
  { 
    "id":119,
    "categoryId":3,
    "imageUrl":"s_yellow_4.jpg"
  },
  { 
    "id":120,
    "categoryId":3,
    "imageUrl":"s_yellow_5.jpg"
  },
  { 
    "id":121,
    "categoryId":3,
    "imageUrl":"s_yellow_6.jpg"
  },
  { 
    "id":122,
    "categoryId":3,
    "imageUrl":"s_yellow_7.jpg"
  },
  { 
    "id":123,
    "categoryId":3,
    "imageUrl":"s_yellow_8.jpg"
  },
  { 
    "id":124,
    "categoryId":3,
    "imageUrl":"s_yellow_9.jpg"
  },
  { 
    "id":125,
    "categoryId":3,
    "imageUrl":"s_yellow_10.jpg"
  },
  { 
    "id":126,
    "categoryId":3,
    "imageUrl":"s_yellow_11.jpg"
  },
  { 
    "id":127,
    "categoryId":3,
    "imageUrl":"s_yellow_12.jpg"
  },
  { 
    "id":128,
    "categoryId":3,
    "imageUrl":"s_yellow_13.jpg"
  },
  { 
    "id":129,
    "categoryId":3,
    "imageUrl":"s_yellow_14.jpg"
  },
  { 
    "id":130,
    "categoryId":3,
    "imageUrl":"s_yellow_15.jpg"
  },
  { 
    "id":131,
    "categoryId":3,
    "imageUrl":"s_yellow_16.jpg"
  },
  { 
    "id":132,
    "categoryId":3,
    "imageUrl":"s_yellow_17.jpg"
  },
  { 
    "id":133,
    "categoryId":3,
    "imageUrl":"s_yellow_18.jpg"
  },
  { 
    "id":134,
    "categoryId":3,
    "imageUrl":"s_yellow_19.jpg"
  },
  { 
    "id":135,
    "categoryId":3,
    "imageUrl":"s_yellow_20.jpg"
  },
  { 
    "id":136,
    "categoryId":3,
    "imageUrl":"s_yellow_21.jpg"
  },
  { 
    "id":137,
    "categoryId":3,
    "imageUrl":"s_yellow_22.jpg"
  },
  { 
    "id":138,
    "categoryId":3,
    "imageUrl":"s_yellow_23.jpg"
  },
  { 
    "id":139,
    "categoryId":3,
    "imageUrl":"s_yellow_24.jpg"
  },
  { 
    "id":140,
    "categoryId":3,
    "imageUrl":"s_yellow_25.jpg"
  },
  { 
    "id":141,
    "categoryId":3,
    "imageUrl":"s_yellow_26.jpg"
  },
  { 
    "id":142,
    "categoryId":3,
    "imageUrl":"s_yellow_27.jpg"
  },
  { 
    "id":143,
    "categoryId":3,
    "imageUrl":"s_yellow_28.jpg"
  },
  { 
    "id":144,
    "categoryId":3,
    "imageUrl":"s_yellow_29.jpg"
  },
  { 
    "id":145,
    "categoryId":3,
    "imageUrl":"s_yellow_30.jpg"
  },
  { 
    "id":146,
    "categoryId":3,
    "imageUrl":"s_yellow_31.jpg"
  },
  { 
    "id":147,
    "categoryId":3,
    "imageUrl":"s_yellow_32.jpg"
  },
  { 
    "id":148,
    "categoryId":3,
    "imageUrl":"s_yellow_33.jpg"
  },
  { 
    "id":149,
    "categoryId":3,
    "imageUrl":"s_yellow_34.jpg"
  },
  { 
    "id":150,
    "categoryId":3,
    "imageUrl":"s_yellow_35.jpg"
  },
  { 
    "id":151,
    "categoryId":3,
    "imageUrl":"s_yellow_36.jpg"
  },
  { 
    "id":152,
    "categoryId":3,
    "imageUrl":"s_yellow_37.jpg"
  },
  { 
    "id":153,
    "categoryId":3,
    "imageUrl":"s_yellow_38.jpg"
  },
  { 
    "id":154,
    "categoryId":3,
    "imageUrl":"s_yellow_39.jpg"
  }
]