import React, { Fragment } from 'react';

import HAN_Logo from '../../../assets/images/han_logo.png';

const Landing: React.FC = (props: any) => {
    return (
      <Fragment>
        <div className="landing-image"></div>
        <main className="landing-wrapper">
          <div className="container center">
            <div className="landing-info">
              <p>
                Kletsborden is een spel waarmee je écht contact kunt maken, op jouw manier. Je stelt elkaar gewone en ongewone vragen. 
                Je deelt verhalen. En.. er ontstaan verrassingen als je je echt in elkaar verdiept. 
                <br /><br />
                Er zijn kaarten over horen, zien en voelen. 
                Je ervaart dat alle mensen beperkingen hebben. En dat alle mensen mogelijkheden hebben.
                <br /><br/>
                Veel plezier!
              </p>
              <div className="landing-buttons">
                <div className="button" onClick={() => props.history.push('/start')}>Online Spelen</div>
                <div className="button" onClick={() => props.history.push('/info')}>Meer info</div>
                <a href="https://www.semmie.net/Kletsborden" target="_BLANK" rel="noopener noreferrer"><div className="button">Spel kopen?</div></a>
              </div>
            </div>
          </div>
          <div className="han-logo">
            <a href="https://www.han.nl" target="_BLANK" rel="noopener noreferrer"><img src={HAN_Logo} alt="Hogeschool van Arnhem en Nijmegen"/></a>
          </div>
        </main>
      </Fragment>
    )
}

export default Landing