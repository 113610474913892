import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import StoryCardElement from './StoryCardElement';
import { storyCards } from '../../../cards/data/storyCards_array';

import BackButton from '../../parts/BackButton';

const StoryCardsList: React.FC = () => {
  const storyCardElements:any = [];

  Object.keys(storyCards).map((key:any) => {
    storyCardElements.push(<StoryCardElement key={storyCards[key].id} card={storyCards[key]} />);
  });

  const randomStoryCard = () => {
    let card;
    const maxNumber = 12;
    const minNumber = 1;
    const randomIndex = Math.floor(Math.random() * (maxNumber - minNumber)) + minNumber;
    card = storyCards[randomIndex];
    
    return "/start/storycard/" + card.id;
  }

  return (
    <Fragment>
      <BackButton text="" location="/start" />
      <aside className="storycards-logo">
        <div></div>
      </aside>
      <main>
        <div className="container center">
          <div className="introduction-card">
            <img src="/storycards/sc_0.png" alt="Doorkletskaart"/>
          </div>
          <div className="storyCardsWrapper">
            {storyCardElements}
            <div className="storyCardItem border-black random-card">
              <Link className="storyCardItemLink" to={randomStoryCard}>Willekeurige kaart!</Link>
            </div>
          </div>
        </div>
      </main>
    </Fragment>
  )
}

export default StoryCardsList