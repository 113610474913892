import React, { useState, useEffect, useContext } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { GameContext } from '../../contexts/GameContext';

import { getCard } from '../../../cards/card_methods';
import { categories } from '../../../cards/data/categories_array';

import Buttons from './Buttons';

import CatYellow from '../../../assets/images/categories/cat_yellow.png';
import CatBlue from '../../../assets/images/categories/cat_blue.png';
import CatRed from '../../../assets/images/categories/cat_red.png';
import CatGreen from '../../../assets/images/categories/cat_green.png';

interface OwnProps {

}

type Props = OwnProps & RouteComponentProps<{ card_id?: string }>;

const Card: React.FC<Props> = (props) => {
    const { game, nextTurn, addToChest, done, addAudioFiles, removeAudioFile, removeCard, randomCard } = useContext(GameContext);
    const [color, setColor] = useState("");
    const [imageURL, setImageURL] = useState("");
    const [audioURL, setAudioURL] = useState("");

    let card: any;
    let audioPath: string;
    let categoryImage: any;

    useEffect(() => {
        if (props.match.params.card_id) {
            addToChest(props.match.params.card_id);
            card = getCard(+props.match.params.card_id);
            let catColor = categories.find(category => category.id === card.categoryId)?.color || "";

            setImageURL('/cards/' + card.imageUrl);
            setColor(catColor);

            if (game.audioFiles.length <= 0) {
                const newAudioArray = fillAudioFilesArray();

                if (catColor === 'blue') {
                    audioPath = newAudioArray[Math.floor(Math.random() * game.audioFiles.length)];
                } else {
                    audioPath = '/cards/' + card.imageUrl.replace(".jpg", ".mp3");
                }

                const filteredAudioArray = newAudioArray.filter(path => path !== audioPath);

                addAudioFiles(filteredAudioArray);

                setAudioURL(audioPath);
            } else {
                if (catColor === 'blue') {
                    if (!game.audioFiles.length) return;

                    audioPath = game.audioFiles[Math.floor(Math.random() * game.audioFiles.length)];

                    removeAudioFile(audioPath);
                } else {
                    audioPath = '/cards/' + card.imageUrl.replace(".jpg", ".mp3");
                }

                setAudioURL(audioPath);
            }
        }
    }, [props]);

    if (!props.match.params.card_id) return <div></div>;

    const fillAudioFilesArray = () => {
        let files: string[] = [];
        for (let i = 0; i < 17 + 1; i++) {
            files.push('/cards/blue/' + i + '.mp3');
        }

        return files;
    }

    const nextPlayer = () => {
        nextTurn();

        props.history.push('/game');
    }

    const displayCard = (cat: number) => {
        const { card } = randomCard(cat);

        if (!card) return;

        removeCard(card.id);

        game.currentScreen = 1;

        props.history.push(`/game/card/${card.id}`);
    }

    const pass = () => {
        if (props.match.params.card_id) {
            nextTurn();

            let card = getCard(+props.match.params.card_id);

            displayCard(card.categoryId);
        }
    }

    const lastRound = () => {
        nextTurn();

        done();

        game.currentScreen = 0;

        props.history.push('/game');
    }

    const endGame = () => {
        props.history.push('/game/end')
    }

    switch(color) {
        case "yellow":
            categoryImage = CatYellow;
            break;
        case "blue":
            categoryImage = CatBlue;
            break;
        case "red":
            categoryImage = CatRed;
            break;
        case "green":
            categoryImage = CatGreen;
            break;
        default:
            categoryImage = null;
    }

    return (
        <main>
            <div className="container center">
                <div className={"card bg-" + color}>
                    <img className="cat-logo" src={categoryImage} alt="Logo van de categorie" />
                    <div className="card-image">
                        <img src={imageURL} alt="Kaart" />
                    </div>
                </div>
            </div>

            {
                !game.lastRound ?
                    <Buttons blue={(color === 'blue') ? true : false} audioFile={"/audio" + audioURL} next={() => nextPlayer()} skip={() => pass()} done={() => lastRound()} playerName={game.players[game.turn].name} />
                    : game.lastPlayer !== game.turn ?
                        <Buttons blue={(color === 'blue') ? true : false} audioFile={"/audio" + audioURL} next={() => nextPlayer()} skip={() => pass()} playerName={game.players[game.turn].name} />
                        :
                        <Buttons blue={(color === 'blue') ? true : false} audioFile={"/audio" + audioURL} skip={() => pass()} end={() => endGame()} playerName={game.players[game.turn].name} />
            }
        </main>
    )
}

export default Card