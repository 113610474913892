import React from 'react';
import { useHistory } from 'react-router-dom';

interface OwnProps {
  uri: string;
  text: string;
}

type Props = OwnProps;

const Button: React.FC<Props> = (props) => {
    const history = useHistory();
    return (
      <div className="button" onClick={() => history.push(props.uri)}>{props.text}</div>
    );
};

export default Button